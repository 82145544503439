























































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import AuthService from "../../services/auth-service";
@Component({
  components: {Header },
})
export default class AuthAccount extends Vue {
  data:any = {username: null, email: null, firstname: null, lastname: null};
  
  retrieve(){
    AuthService.getauth()
      .then((response) => {
          this.data = response.data[0];
      })
      .catch((e) => {
        console.log(e);
      });
  }


  mounted() {
    this.retrieve()
  }

}
